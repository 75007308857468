import { createSelector, Selector } from 'reselect';

import { State } from 'store';

import { IdbUser } from 'interfaces';

const userState = (state: State) => state.userReducer;

export const selectUser: Selector<State, IdbUser | null | undefined> = createSelector(userState, ({ user }) => user);

export const selectLoading: Selector<State, boolean> = createSelector(userState, ({ loading }) => loading);
