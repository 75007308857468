import { CircularProgress } from '@material-ui/core';
import React from 'react';

import { useStyles } from './styles';

const Loader: React.FC = () => {
  const classes = useStyles();
  return <CircularProgress size={24} className={classes.progress} />;
};

export default Loader;
