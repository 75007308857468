import HttpClient from './http-client';

import { IdbUser } from 'interfaces';

export interface IResponse {
  data: {};
}

interface ICreateUser extends IResponse {
  token: string;
  user: IdbUser;
}

export default class MainApi extends HttpClient {
  public constructor() {
    super(process.env.REACT_APP_BASE_URL);
  }

  public createUser(data: IdbUser): Promise<ICreateUser> | undefined {
    return this.instance?.post('/user/login', data);
  }
}
