import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';

import history from 'utils/history';

import userReducer from './user';
import transactionsReducer from './transactions';
import accountsReducer from './accounts';
import balanceTrendsReducer from './balanceTrends';
import dashboardReducer from './dashboard';
import calendarReducer from './calendar';

export const rootReducer = combineReducers({
  router: connectRouter(history),
  userReducer,
  transactionsReducer,
  accountsReducer,
  balanceTrendsReducer,
  dashboardReducer,
  calendarReducer,
});
