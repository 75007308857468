import { createReducerFunction, ImmerReducer } from 'immer-reducer';

import { IAccount, IAccountsState } from 'interfaces';

const initialState: IAccountsState = {
  accountDetails: null,
  isLoading: false,
  accounts: [],
  isAccountCreating: false,
  transactionCount: 0,
};

export class AccountReducer extends ImmerReducer<IAccountsState> {
  setIsAccountCreating(status: boolean) {
    this.draftState.isAccountCreating = status;
  }
  setIsLoading(status: boolean) {
    this.draftState.isLoading = status;
  }
  setAccountDetails(accountDetails: IAccount | null | undefined) {
    this.draftState.accountDetails = accountDetails;
  }
  setAccounts(accounts: IAccount[] | [] | undefined) {
    this.draftState.accounts = accounts;
  }
  setTransactionCount(count: number | undefined) {
    this.draftState.transactionCount = count;
  }
  clearAccountDetails() {
    this.draftState.accountDetails = null;
    this.draftState.isAccountCreating = false;
  }
  clearAccounts() {
    this.draftState.accounts = [];
    this.draftState.isAccountCreating = false;
    this.draftState.isLoading = false;
  }
}

export default createReducerFunction(AccountReducer, initialState);
