import { ISelector } from './selector';

export enum Variant {
  FILLED = 'filled',
  OUTLINED = 'outlined',
  STANDARD = 'standard',
}

export interface IFormActions {
  select?: boolean;
  autoFocus?: boolean;
  label?: string;
  fullWidth?: boolean;
  onChange: (event: React.ChangeEvent<HTMLInputElement> | any) => void;
  id?: string;
  name?: string;
  type?: string;
  value: string;
  onFocus?: () => void;
  onBlur?: () => void;
  helperText?: string;
  error?: boolean;
  variant?: Variant;
  FormHelperTextProps?: { classes: any };
  options?: ISelector[];
}
