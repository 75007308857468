import { createReducerFunction, ImmerReducer } from 'immer-reducer';

import { ITransaction, ITransactionFilters, ITransactionState } from 'interfaces';

const initialState: ITransactionState = {
  transaction: null,
  isLoading: false,
  isTransactionCreating: false,
  transactions: [],
  isFilterApplied: false,
  totalDocuments: 0,
  filters: {
    accounts: [],
    transactionType: [],
    categories: [],
    dateRange: {
      pastThirtyDays: false,
      custom: false,
    },
    customDateRange: {
      from: null,
      to: null,
    },
  },
};

export class TransactionsReducer extends ImmerReducer<ITransactionState> {
  setTransaction(transaction: ITransaction | undefined) {
    this.draftState.transaction = transaction;
  }
  setIsLoading(status: boolean) {
    this.draftState.isLoading = status;
  }
  setIsTransactionCreating(status: boolean) {
    this.draftState.isTransactionCreating = status;
  }
  setTransactions(transactions: ITransaction[] | undefined) {
    this.draftState.transactions = transactions;
  }
  setTotalDocuments(count: number | undefined) {
    this.draftState.totalDocuments = count;
  }
  setFilters(filters: ITransactionFilters) {
    this.draftState.filters = filters;
  }
  setIsFilterApplied(status: boolean) {
    this.draftState.isFilterApplied = status;
  }
  clearTransactions() {
    this.draftState.transactions = [];
    this.draftState.isTransactionCreating = false;
    this.draftState.isLoading = false;
  }
  clearTransaction() {
    this.draftState.transaction = null;
    this.draftState.isTransactionCreating = false;
  }
  clearFilters() {
    this.draftState.filters = initialState.filters;
    this.draftState.isFilterApplied = false;
  }
}

export default createReducerFunction(TransactionsReducer, initialState);
