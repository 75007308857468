import { createReducerFunction, ImmerReducer } from 'immer-reducer';

import { ICalendarState, IDayTransactions, IMonthTransactions, ITransaction } from 'interfaces';

const initialState: ICalendarState = {
  isLoading: false,
  dayTransactions: [],
  monthTransactions: [],
  overlappedTransactions: [],
};

export class CalendarReducer extends ImmerReducer<ICalendarState> {
  setIsLoading(status: boolean) {
    this.draftState.isLoading = status;
  }
  setDayTransactions(dayTransactions: IDayTransactions[] | undefined) {
    this.draftState.dayTransactions = dayTransactions;
  }
  setMonthTransactions(monthTransactions: IMonthTransactions[] | undefined) {
    this.draftState.monthTransactions = monthTransactions;
  }
  setOverlappedTransactions(overlappedTransactions: ITransaction[]) {
    this.draftState.overlappedTransactions = overlappedTransactions;
  }
  clearCalendarState() {
    this.draftState = initialState;
  }
}

export default createReducerFunction(CalendarReducer, initialState);
