import React from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  MenuItem,
} from '@material-ui/core';

import { useStyles } from './styles';

import Loader from '../Loader';

import { IFormActions, ISelector } from 'interfaces';

interface IPromptProps {
  title: string;
  isOpen: boolean;
  onClose: () => void;
  isLoading?: boolean;
  okButtonText?: string;
  showCancelButton?: boolean;
  cancelButtonText?: string;
  okButtonAction: () => void;
  cancelButtonAction?: () => void;
  dialogContentText: string | React.ReactNode;
  formActions: IFormActions[];
  isClosable?: boolean;
}

const Prompt: React.FC<IPromptProps> = ({
  title,
  isOpen,
  onClose,
  isLoading,
  okButtonText = 'Save',
  showCancelButton = true,
  cancelButtonText = 'Cancel',
  okButtonAction,
  cancelButtonAction,
  dialogContentText,
  formActions,
  isClosable,
}) => {
  const classes = useStyles();

  const handleCancelOnClick = () => {
    if (cancelButtonAction) {
      return cancelButtonAction();
    }

    onClose();
  };

  return (
    <div className={classes.root}>
      <Dialog
        open={isOpen}
        onClose={onClose}
        aria-labelledby='prompt-dialog-title'
        disableBackdropClick={!isClosable}
      >
        {isLoading ? (
          <Loader />
        ) : (
          <>
            <DialogTitle id='prompt-dialog-title'>{title}</DialogTitle>
            <DialogContent>
              <DialogContentText>{dialogContentText}</DialogContentText>
              {formActions.map((form: IFormActions, index: number) => {
                return (
                  <TextField
                    select={form?.select || false}
                    key={index}
                    autoFocus={form?.autoFocus || false}
                    margin='dense'
                    id={form?.id}
                    name={form?.name}
                    label={form?.label}
                    type={form.type}
                    fullWidth={form?.fullWidth || true}
                    value={form?.value}
                    onChange={form.onChange}
                    variant={form?.variant}
                  >
                    {form?.select &&
                      form?.options?.map((option: ISelector, index: number) => (
                        <MenuItem key={index} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                  </TextField>
                );
              })}
            </DialogContent>
            <DialogActions>
              {showCancelButton && <Button onClick={handleCancelOnClick}>{cancelButtonText}</Button>}
              <Button onClick={okButtonAction}>{okButtonText}</Button>
            </DialogActions>
          </>
        )}
      </Dialog>
    </div>
  );
};

export default Prompt;
