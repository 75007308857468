import React, { useState } from 'react';

export const useInput = (initialValue: any, options: any = {}) => {
  const [value, setValue] = useState(initialValue);
  const [error, setError] = useState<boolean>(false);
  const [dirty, setDirty] = useState<boolean>(false);

  const handleOnChange = (event: React.ChangeEvent<HTMLInputElement> | any) => {
    let value = null;
    if (event && Object.prototype.hasOwnProperty.call(event, 'target')) {
      value = event.target.value;
    } else {
      value = event;
    }

    let isInvalidValue = false;

    if (options?.validate) {
      isInvalidValue = options.validate(value);
    }

    setError(isInvalidValue);

    if (isInvalidValue) {
      return;
    }

    setValue(value);
    setDirty(true);
  };

  return {
    value,
    setValue,
    error,
    reset: () => setValue,
    dirty,
    bind: {
      onChange: handleOnChange,
    },
  };
};
