import React from 'react';

import * as Interfaces from './interfaces';

const Login = React.lazy(() => import('components/Login'));
const Dashboard = React.lazy(() => import('components/Dashboard'));
const Accounts = React.lazy(() => import('components/Accounts'));
const AccountDetails = React.lazy(() => import('components/AccountDetails'));
const Transactions = React.lazy(() => import('components/Transactions'));
const TransactionDetails = React.lazy(() => import('components/TransactionDetails'));
const Calendar = React.lazy(() => import('components/Calendar'));
const Settings = React.lazy(() => import('components/Settings'));

export const routes: Interfaces.IRoutes[] = [
  { path: '/login', component: Login, exact: true },
  { path: '/dashboard', component: Dashboard, exact: true, isProtected: true },
  { path: '/accounts', component: Accounts, exact: true, isProtected: true },
  { path: '/account/:id', component: AccountDetails, exact: true, isProtected: true },
  { path: '/transactions', component: Transactions, exact: true, isProtected: true },
  { path: '/transactions/:transactionId', component: TransactionDetails, exact: true, isProtected: true },
  { path: '/calendar', component: Calendar, exact: true, isProtected: true },
  { path: '/settings', component: Settings, exact: true, isProtected: true },
];
