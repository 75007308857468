import { IAccount } from './accounts';
import { ISelector } from './selector';

export enum TransactionType {
  INCOME = 'income',
  EXPENSE = 'expense',
}

export interface ITransaction {
  _id?: string | number;
  transactionType: TransactionType;
  account: IAccount;
  amount: string | number;
  currency?: string | null;
  label: string | null;
  date: string | number;
  userId: string | number;
  category: ISelector | null;
  createdAt?: any;
  updatedAt?: any | null;
  balance?: number | string | null;
  previousBalance?: number | string | null;
}

export interface ITransactionFilters {
  accounts: IAccount[] | [] | null | undefined;
  transactionType: ISelector[] | [] | null;
  categories: ISelector[] | [] | null;
  dateRange: {
    pastThirtyDays: boolean;
    custom: boolean;
  };
  customDateRange: {
    from: number | string | null;
    to: number | string | null;
  };
}

export interface ITransactionState {
  transaction: ITransaction | null | undefined;
  isLoading: boolean;
  isTransactionCreating: boolean;
  transactions: ITransaction[] | [] | undefined;
  isFilterApplied: boolean;
  totalDocuments: number | undefined;
  filters: ITransactionFilters;
}
