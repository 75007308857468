import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { currencies } from 'model/account';

import Prompt from 'components/common/Prompt';

import { useInput } from 'hooks/useInput';

import { setUserCurrency } from 'store/actions/user';
import { selectLoading } from 'store/selectors/user';

import { Variant } from 'interfaces';

interface ISetCurrencyProps {
  isOpen: boolean;
  onClose?: () => void;
  value?: string | null;
}

const SetCurrency: React.FC<ISetCurrencyProps> = ({ isOpen, onClose, value }) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const isLoading = useSelector(selectLoading);

  const [isPromptOpen, setIsPromptOpen] = useState<boolean>(false);
  const [isClosable, setIsClosable] = useState<boolean>(false);

  const {
    value: currency,
    setValue: setCurrency,
    bind: bindCurrency,
  } = useInput('');

  useEffect(() => {
    if (!isOpen) {
      handlePromptClose();
    }

    if (isOpen) {
      handlePromptOpen();
    }
  }, [isOpen]);

  useEffect(()=> {
    if (!onClose) {
      return;
    }

    setIsClosable(true);
  }, [onClose]);

  useEffect(() => {
    if (!value) {
      return;
    }

    setCurrency(value);
  }, [value]);

  const handlePromptOpen = () => setIsPromptOpen(true);
  const handlePromptClose = () => {
    if (isClosable && onClose) {
      onClose();
    }

    setIsPromptOpen(false);
  };

  const handlePromptSave = () => {
    const data = {
      currency: currency,
    };

    dispatch(setUserCurrency(data, history));
    handlePromptClose();
  };

  return (
    <Prompt
      isOpen={isPromptOpen}
      onClose={handlePromptClose}
      isLoading={isLoading}
      title='Please select currency'
      dialogContentText='Select your currency. It will be your currency for all the accounts and transactions'
      okButtonAction={handlePromptSave}
      cancelButtonAction={handlePromptClose}
      showCancelButton={false}
      isClosable={isClosable}
      formActions={[
        {
          select: true,
          id: 'setCurrency',
          name: 'setCurrency',
          label: 'Currency *',
          helperText: 'Please select your currency',
          onChange: bindCurrency.onChange,
          value: currency,
          options: currencies,
          variant: Variant.OUTLINED,
        },
      ]}
    />
  );
};

export default SetCurrency;
