import HttpClientProtected from 'api/http-client-protected';
import { IResponse } from './main';

import {
  IAccount,
  IBalanceExpenditure,
  IBalanceTrends,
  IDayTransactions,
  IdbUser,
  IMonthTransactions,
  ITransaction,
  ITransactionFilters,
} from 'interfaces';

interface ICreateAccount extends IResponse {
  accountId?: string;
  message: string;
}

interface IGetAccountDetails extends IResponse {
  account: IAccount;
  transactionCount: number;
}

interface IGetAccounts extends IResponse {
  accounts: IAccount[] | [];
}

interface IArchiveAccount extends IResponse {
  accountId: string | number;
  message: string;
}

interface ICreateTransaction extends IResponse {
  transactionId?: string;
  message: string;
}

interface IGetUser extends IResponse {
  user?: IdbUser;
  message?: string;
}

interface IGetTransactions extends IResponse {
  transactions: ITransaction[] | [];
  totalDocuments: number;
}

interface IGetTransactionById extends IResponse {
  transaction: ITransaction;
}

interface IGetBalanceTrends extends IResponse {
  balanceTrends: IBalanceTrends[] | [];
}

interface IGetDashboardDetails extends IResponse {
  balanceExpenditure: IBalanceExpenditure;
  incomeTransactions: ITransaction[] | [];
  expenseTransactions: ITransaction[] | [];
  miniStatement: ITransaction[] | [];
}

interface IArchiveTransaction extends IResponse {
  transactionId: string;
  message: string;
}

interface IGetDayTransactions extends IResponse {
  transactions: IDayTransactions[] | undefined;
}

interface IGetMonthTransactions extends IResponse {
  transactions: IMonthTransactions[] | undefined;
}

interface IGetTransactionsByIds extends IResponse {
  transactions: ITransaction[];
}

export default class MainProtected extends HttpClientProtected {
  public constructor() {
    super(process.env.REACT_APP_BASE_URL);
  }

  public getMe(): Promise<IGetUser> | undefined {
    return this.instance?.get(`/user/me`);
  }

  public setUserCurrency(data: { currency: string }): Promise<IGetUser> | undefined {
    return this.instance?.post(`/user/currency`, data);
  }

  public createAccount(data: IAccount): Promise<ICreateAccount> | undefined {
    return this.instance?.post(`/account`, data);
  }

  public updateAccount(data: IAccount): Promise<ICreateAccount> | undefined {
    return this.instance?.put(`/account/${data.id}`, data);
  }

  public getAccounts(): Promise<IGetAccounts> | undefined {
    return this.instance?.get(`/account`);
  }

  public getAccountDetails(accountId: string): Promise<IGetAccountDetails> | undefined {
    return this.instance?.get(`/account/${accountId}`);
  }

  public archiveAccount(accountId: string | undefined): Promise<IArchiveAccount> | undefined {
    return this.instance?.delete(`/account/${accountId}`);
  }

  public createTransaction(data: ITransaction): Promise<ICreateTransaction> | undefined {
    return this.instance?.post(`/transaction`, data);
  }

  public updateTransaction(data: ITransaction, id: string): Promise<ICreateTransaction> | undefined {
    return this.instance?.put(`/transaction/${id}`, data);
  }

  public archiveTransaction(transactionId: string | undefined): Promise<IArchiveTransaction> | undefined {
    return this.instance?.delete(`/transaction/${transactionId}`);
  }

  public getTransactionsByUserId(
    skip: number,
    limit: number,
    filters?: ITransactionFilters
  ): Promise<IGetTransactions> | undefined {
    return this.instance?.post(`/transaction/list?skip=${skip}&limit=${limit}`, filters);
  }

  public getBalanceTrendsByAccountId(accountId: string): Promise<IGetBalanceTrends> | undefined {
    return this.instance?.get(`/balance-trends/${accountId}`);
  }

  public getTransactionById(transactionId: string): Promise<IGetTransactionById> | undefined {
    return this.instance?.get(`/transaction/${transactionId}`);
  }

  public getDashboardDetails(): Promise<IGetDashboardDetails> | undefined {
    return this.instance?.get(`/dashboard`);
  }

  public getDayTransactions(date: string): Promise<IGetDayTransactions> | undefined {
    return this.instance?.get(`/calendar/transactions/days/${date}`);
  }

  public getMonthTransactions(month: string, year: string): Promise<IGetMonthTransactions> | undefined {
    return this.instance?.get(`/calendar/transactions/months/${month}/${year}`);
  }

  public getTransactionsByIds(transactionIds: {
    transactionIds: string[];
  }): Promise<IGetTransactionsByIds> | undefined {
    return this.instance?.post('/transaction/transactions', transactionIds);
  }
}
