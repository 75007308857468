import { useLocation } from 'react-router-dom';

const useQuery = () => {
  const location = useLocation();

  if (location) {
    return new URLSearchParams(location.search);
  }
};

export { useQuery };
