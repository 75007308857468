import { Suspense, useEffect, useState } from 'react';
import { Switch, Route, Redirect, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { routes } from 'routes';

import Loader from 'components/common/Loader';
import SetCurrency from 'components/SetCurrency';

import * as Interfaces from 'interfaces';

import { getMe } from 'store/actions/user';
import { selectUser } from 'store/selectors/user';

import ProtectedRoute from 'utils/ProtectedRoutes';
import LocalStorage from 'utils/localStorage';

import { useQuery } from 'hooks/useQuery';

function App() {
  const history = useHistory();
  const query = useQuery();

  const dispatch = useDispatch();

  const user = useSelector(selectUser);

  const isLoggedIn = LocalStorage.getToken();

  const [hasCurrency, setHasCurrency] = useState<string | null | undefined>();

  useEffect(() => {
    if (isLoggedIn) {
      dispatch(getMe());
    }
  }, [dispatch, isLoggedIn]);

  useEffect(() => {
    if (!user || user?.currency?.length) {
      return;
    }

    history.replace(`${history?.location?.pathname}?setCurrency=true`);
  }, [user]);

  useEffect(() => {
    setHasCurrency(query?.get('setCurrency'));
  }, [query]);

  return (
    <>
      {hasCurrency === 'true' && <SetCurrency isOpen />}
        <Suspense fallback={<Loader />}>
          <Switch>
            {routes &&
              routes.map((route: Interfaces.IRoutes, index: number) => {
                if (route.isProtected) {
                  return (
                    <ProtectedRoute
                      key={index}
                      path={`${route.path}`}
                      exact={route.exact}
                      component={route.component}
                    />
                  );
                }
                return <Route key={index} path={`${route.path}`} exact={route.exact} component={route.component} />;
              })}
            <Route
              path='/'
              render={() => {
                if (isLoggedIn) {
                  return <Redirect to='/dashboard' />;
                } else {
                  return <Redirect to='/login' />;
                }
              }}
            />
          </Switch>
        </Suspense>
    </>
  );
}

export default App;
