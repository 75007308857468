import { createReducerFunction, ImmerReducer } from 'immer-reducer';

import { IBalanceTrendsState, IBalanceTrends } from 'interfaces';

const initialState: IBalanceTrendsState = {
  isLoading: false,
  balanceTrends: [],
};

export class BalanceTrendsReducer extends ImmerReducer<IBalanceTrendsState> {
  setIsLoading(status: boolean) {
    this.draftState.isLoading = status;
  }
  setBalanceTrends(balanceTrends: IBalanceTrends[] | [] | undefined) {
    this.draftState.balanceTrends = balanceTrends;
  }
  clearBalanceTrends() {
    this.draftState.balanceTrends = [];
    this.draftState.isLoading = false;
  }
}

export default createReducerFunction(BalanceTrendsReducer, initialState);
