import { createReducerFunction, ImmerReducer } from 'immer-reducer';

import { IdbUser, IUserState } from 'interfaces';

const initialState: IUserState = {
  user: null,
  loading: false,
};

export class UserReducer extends ImmerReducer<IUserState> {
  setUser(user: IdbUser | null | undefined) {
    this.draftState.user = user;
  }

  setLoading(status: boolean) {
    this.draftState.loading = status;
  }
}

export default createReducerFunction(UserReducer, initialState);
