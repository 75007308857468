export enum LOCALS {
  TOKEN = 'token',
}

export default class LocalStorage {
  static getToken() {
    const token = window.localStorage.getItem(LOCALS.TOKEN);
    return token;
  }

  static setToken(token: string | undefined) {
    if (token) {
      window.localStorage.setItem(LOCALS.TOKEN, token);
    }
  }

  static clearStorageByKey(key: string) {
    window.localStorage.removeItem(key);
  }

  static clearStorage() {
    window.localStorage.clear();
  }
}
