import { ISelector } from 'interfaces';

export enum DeductionType {
  INCREMENT = 'INCREMENT',
  DECREMENT = 'DECREMENT',
}

export const currencies: ISelector[] = [
  {
    value: 'USD',
    label: 'USD ($)',
    text: '$',
  },
  {
    value: 'EUR',
    label: 'EUR (€)',
    text: '€',
  },
  {
    value: 'BTC',
    label: 'BTC (฿)',
    text: '฿',
  },
  {
    value: 'JPY',
    label: 'JPY (¥)',
    text: '¥',
  },
  {
    value: 'INR',
    label: 'INR (₹)',
    text: '₹',
  },
];

export const accountTypes: ISelector[] = [
  {
    value: 'general',
    label: 'General',
  },
  {
    value: 'cash',
    label: 'Cash',
  },
  {
    value: 'current_account',
    label: 'Current Account',
  },
  {
    value: 'savings_account',
    label: 'Savings Account',
  },
  {
    value: 'credit_card',
    label: 'Credit Card',
  },
  {
    value: 'debit_card',
    label: 'Debit Card',
  },
  {
    value: 'account_overdraft',
    label: 'Account with Overdraft',
  },
  {
    value: 'bouns',
    label: 'Bouns',
  },
  {
    value: 'insurance',
    label: 'Insurance',
  },
  {
    value: 'investment',
    label: 'Investment',
  },
  {
    value: 'loan',
    label: 'Loan',
  },
  {
    value: 'mortgage',
    label: 'Mortgage',
  },
];
