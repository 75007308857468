import { createActionCreators } from 'immer-reducer';

import { replace } from 'connected-react-router';

import { UserReducer } from 'store/reducers/user';
import { AsyncAction } from './common';

import LocalStorage from 'utils/localStorage';

import { IdbUser } from 'interfaces';

export const userActions = createActionCreators(UserReducer);

export type UserActions = ReturnType<typeof userActions.setUser> | ReturnType<typeof userActions.setLoading>;

export const createUser = (user: IdbUser): AsyncAction => async (dispatch, getState, { mainApi }) => {
  try {
    dispatch(userActions.setLoading(true));

    const data = await mainApi.createUser(user);

    LocalStorage.setToken(data?.token);

    await dispatch(userActions.setUser(data?.user));
    dispatch(userActions.setLoading(false));
    dispatch(replace('/dashboard'));
  } catch (err: any) {
    console.error(err);
    dispatch(userActions.setLoading(false));
  }
};

export const getMe = (): AsyncAction => async (dispatch, getState, { protectedMainApi }) => {
  try {
    dispatch(userActions.setLoading(true));

    const data = await protectedMainApi.getMe();
    if (data?.message) {
      dispatch(userActions.setLoading(false));
      return dispatch(replace('/login'));
    }

    await dispatch(userActions.setUser(data?.user));

    dispatch(userActions.setLoading(false));
  } catch (err: any) {
    console.error(err);
    dispatch(userActions.setLoading(false));
  }
};

export const setUserCurrency = (data: { currency: string }, history: any): AsyncAction => async (
  dispatch,
  getState,
  { protectedMainApi }
) => {
  try {
    dispatch(userActions.setLoading(true));

    const userInfo = await protectedMainApi.setUserCurrency(data);

    dispatch(userActions.setUser(userInfo?.user));
    dispatch(replace(`${history.location.pathname}`));
    dispatch(userActions.setLoading(false));
  } catch (err: any) {
    console.error(err);
    dispatch(userActions.setLoading(false));
  }
};
