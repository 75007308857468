import { createReducerFunction, ImmerReducer } from 'immer-reducer';
import { IBalanceExpenditure, IDashboardState, ITransaction } from 'interfaces';

const initialState: IDashboardState = {
  balanceExpenditure: null,
  expenses: [],
  incomes: [],
  miniStatement: [],
  isLoading: false,
};

export class DashboardReducer extends ImmerReducer<IDashboardState> {
  setBalanceExpenditure(dashboardDetails: IBalanceExpenditure | null | undefined) {
    this.draftState.balanceExpenditure = dashboardDetails;
  }

  setExpenses(expenses: ITransaction[] | [] | null | undefined) {
    this.draftState.expenses = expenses;
  }

  setIncomes(incomes: ITransaction[] | [] | null | undefined) {
    this.draftState.incomes = incomes;
  }

  setMiniStatement(statement: ITransaction[] | [] | null | undefined) {
    this.draftState.miniStatement = statement;
  }

  setIsLoading(status: boolean) {
    this.draftState.isLoading = status;
  }

  clearDashboardState() {
    this.draftState = initialState;
  }
}

export default createReducerFunction(DashboardReducer, initialState);
