import React from 'react';
import { RouteProps, Route, Redirect } from 'react-router-dom';
import LocalStorage from './localStorage';

const ProtectedRoute: React.FC<RouteProps> = (props) => {
  const isLoggedIn = LocalStorage.getToken();

  return isLoggedIn ? <Route {...props} /> : <Redirect to='/' />;
};

export default ProtectedRoute;
