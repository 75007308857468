import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import { routerMiddleware } from 'connected-react-router';

import { rootReducer } from './reducers/rootReducer';

import history from 'utils/history';

import MainApi from 'api/main';
import ProtectedMainApi from 'api/protected-main';

const composeEnhancers = (window && (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose;

const mainApi = new MainApi();
const protectedMainApi = new ProtectedMainApi();

export const getApiArguments = () => ({
  mainApi,
  protectedMainApi,
});

const enhancer = composeEnhancers(
  applyMiddleware(routerMiddleware(history), thunk.withExtraArgument(getApiArguments()))
);

export type State = ReturnType<typeof rootReducer>;

export default createStore(rootReducer, enhancer);
